import Cookies from "js-cookie";


function AppContent() {
  localStorage.setItem("url", window.location.href);
  setCookieForUrl(window.location.href)
  window.close();

  function setCookieForUrl(valuePass){
    let name = "url",
    value = valuePass,
    pathe = "/",
    domain = ".zycus.com",
    //domain = "localhost",
    ATS_getExpire = function () {
      return "ATS_getExpire";
    };

  let curCookie =
    name +
    "$" +
    value +
    ", expires=" +
    ATS_getExpire() +
    ",path=" +
    pathe +
    ", domain=" +
    domain;
  Cookies.set("session", curCookie, {
    domain: ".zycus.com",
    sameSite: "none",
    httpOnly: false,
    secure: true,
  });
  console.log("cookie", window.document.cookie);
  document.cookie = curCookie;
  window.document.cookie = `url=${value}`;
  console.log("cookie", window.document.cookie);
  }

  return (
    <div>
      <p>App Content</p>
    </div>
  );
}

export default AppContent;
