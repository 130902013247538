// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
//import authStore from "./Keys";

//const auth =new authStore();
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        instrumentationKey:"d36ac234-0532-42c7-adc6-6cac4787ba3e",
        extensions: [reactPlugin],
    }
})
ai.loadAppInsights()

export const appInsights = ai.appInsights
// eslint-disable-next-line import/no-anonymous-default-export
export default (Component) => withAITracking(reactPlugin, Component)
