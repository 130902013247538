import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";

function CustomDomain() {
  console.log("CustomDomain");
  let { url } = useParams();
  url=url.split('=');
  const [callBackUrl] = useState("");

  useEffect(() => {
    let customDomain = url[1];
    let uniqueID = "";//localStorage.getItem("uniqueID");
    let urlToget = "";//localStorage.getItem("url");
    console.log(customDomain);
    localStorage.removeItem("url");
    if (customDomain !== null || customDomain !== undefined) {
      try{
        microsoftTeams.app.initialize().then(() => {
          microsoftTeams.authentication
            .authenticate({
              url: `${window.location.origin}/assignlocation?${customDomain}`,
              isExternal: false,
              width: 600,
              height: 535,
            })
            .then((result) => {
              console.log("Login succeeded: " + result);
              microsoftTeams.app.notifySuccess()
            })
            .catch((reason) => {
              console.log("Login failed: " + reason);
              microsoftTeams.app.notifySuccess()
             // CallApiForToken();
            
             console.log(document.cookie.split(";"));
             document.cookie.split(";").forEach((item)=>{
              if(item.includes("session=url"))
              {
                  console.log(item?.split("$")[1])
                  urlToget=  item?.split("$")[1]?.split("%2C%20")[0]?.split("=")[1]
              }
              if(item.includes("ZAUTH_REDIRECT_URL"))
              {
               console.log(item.split("%3D")[4])
               uniqueID=item.split("%3D")[4]
               }

          })

         
             //let setUpValue = url?.split("%2C%20")[0].split("=")[1]//url.split("=")[1];
           //  console.log(setUpValue)
             console.log("uniqueID",uniqueID)
             console.log("urlToget",urlToget)
             if(uniqueID && urlToget)
             {
              console.log("Inside IF with uniqueID and urlToget")
              const returnToTeams ={
                uniqueID:uniqueID,
                setUpValue:urlToget
               };
               microsoftTeams.tasks.submitTask(returnToTeams);
               microsoftTeams.tasks.submitTask();
             }
             else{
              alert("Something went wrong.Please try after Sometime.")
             }
            });
        });
      }catch(err)
      {

      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Login to Azure AD and get access to Microsoft Graph

  return (
    <div>
      <p>{callBackUrl ? `Callback Url ${callBackUrl}` : ""}</p>
    </div>
  );
}

export default CustomDomain;
