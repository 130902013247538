import usePagination from '@mui/material/usePagination';

import { Stack, PrimaryButton, DefaultButton } from "@fluentui/react";

const Paginate = (props) => {
  const { items } = usePagination(props);

  return (
    <Stack horizontal>
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          // Ellipsis for when we have too many pages to show
          children = "…";
        } else if (type === "page") {
          children = (
            // Page components (1, 2, 3, ...)
            <DefaultButton
              styles={{ root: { fontWeight: selected ? "bold" : undefined } }}
              {...item}
            >
              {page}
            </DefaultButton>
          );
        } else {
          // Previous/Next buttons
          children = <PrimaryButton {...item}>{type}</PrimaryButton>;
        }

        // Individual component wrapper
        return <span key={index}>{children}</span>;
      })}
    </Stack>
  );
};

export default Paginate;
