// @ts-ignore
import {
  DetailsListLayoutMode,
  mergeStyles,
  SelectionMode,
  TextField,
  ThemeProvider,
} from "@fluentui/react";
import Strings from '../src/pages/Strings';
import { ProgressBar } from "react-loader-spinner";
import "./index.css";
import { getHeaders, getHeaderswithApprovalList } from "./Headers";
import { appInsights } from "./AppInsights";
import Paginate from "./pages/Paginate";
import messageAdpCard from './MessageAdpCard.json'
import {  getMstKeys } from "./Common";

import { FilterIcon } from "@fluentui/react-icons-mdl2";
import { registerIcons } from "@fluentui/react/lib/Styling";
import { EditableGrid, EventEmitter, EventType } from "fluentui-editable-grid";
import { Fabric } from "office-ui-fabric-react";
import * as React from "react";
import { useState, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { app } from "@microsoft/teams-js";
import { getCall, postCall } from "./Api/services";
import { getFormatedData } from "./shared/dataFormat";

import filter from "./icons8-filter-and-sort-16.png";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { mergeStyleSets } from "@fluentui/react";
import { EditControlType, StringOperators } from "fluentui-editable-grid";
import authStore from "./Keys";
// import { pages } from "@microsoft/teams-js";
import { pages } from "@microsoft/teams-js";
import URL from "./URL";

export const divStyle = {
  marginLeft: "6vh",
  border: "1px solid #989afa",
  color: "#464EB8",
  fontWeight: "600",
  background: "inherit",
  borderRadius: "4px",
  cursor: "pointer",
  height: "27px",
};
export const buttonStyle = {
  border: "1px solid #989afa",
  color: "#464EB8",
  fontWeight: "600",
  background: "inherit",
  borderRadius: "4px",
  cursor: "pointer",
  height: "27px",
};
export const notificationButtonStyle = {
  border: "1px solid #87d498",
  color: "#2a8c4b",
  background: "inherit",
  fontSize: "xx-small",
  paddingTop: "0px",
  paddingBottom: "0px",
  fontWeight: "500",
  borderRadius: "3px",
};
export const notificationStyle = {
  display: "flex",
  flexDirection: "column",
};
export const idStyle = {
  fontSize: "x-small",
};
export const notificationTextStyle = {
  fontWeight: "400",
  fontSize: "x-small",
};

export const scrollMobile={
  margin: "40px, 40px",
  padding: "15px",
  width:"100%",
  "overflow-x": "scroll",
  "overflow-y": "hidden",
  "white-space": "nowrap",
}

export const scrollHorizontal = {
  margin: "4px, 4px",
  padding: "4px",
  width:"950px",
  "overflow-x": "auto",
  "overflow-y": "hidden",
  "white-space": "nowrap",
};
export const textRow1 = {
  fontWeight: "500",
};
export const textRow2 = {
  fontWeight: "400",
};
export const textRowDocumentID = {
  fontWeight: "500",
  cursor: "pointer",
};

export const selectionRange = {
  startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
  endDate: new Date(),
  key: "selection",
};

export const classNames = mergeStyleSets({
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  headerStyle: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: "1%",
    overflow: "hidden",
    right: 0,

  },
  gridStyle: {
    overflow: "hidden",
  },
  filterIconStyle: {
    fontSize: 20,
    color: "#106ebe",
  },
});
registerIcons({
  icons: {
    FilterIcon: <FilterIcon />,
  },
});

const JsonDataDisplay = () => {
  let userObjectId: string | null | undefined;
  let theme: string;
  let mstKeys = Strings;
  let isMobile: string;
  const [mstJson,setMstKeysJson] = useState({
    MST_Date_and_Time: "",
    MST_ID: "",
    MST_Description: "",
  });

  const [items, setItems] = useState([]);
  const [, setOriginalItemsList] = useState([]);
  const [zauthTokenVal, setzauthToken] = useState("");
  const msteamsIcon = require("./images/msteamsicon.png");

  const [isErrorMessage, setisErrorMessage] = useState(false);
  const [errmessage, setErrmessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [env, setEnv] = useState("");
  const [dsso, setIsdsso] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [email, setEmail] = useState("");
  const [appTheme, setAppTheme] = useState("light");
  const [selUrl, setSelUrl] = useState("");
  const [columns, setColumns]:any  = useState([]);
  const [witdhAdj, setWitdhAdj] = useState(false);

  useEffect(() => {
    console.log("Useeffect",process.env.REACT_APP_IDENTITY_CLIENT_ID);
    app.initialize().then( () => {
      app.getContext().then((context) => {
        //console.log("Inside app.getContext");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        userObjectId = context?.user?.id;
              // eslint-disable-next-line react-hooks/exhaustive-deps
        theme = context?.app?.theme;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isMobile =context?.app?.host?.clientType;
      if(isMobile === "android" || isMobile === "ios" || isMobile === "teamsRoomsAndroid" || isMobile === "teamsPhones")
      {
        setWitdhAdj(true) ;
      }
        //console.log("userObjectId", userObjectId);
        if (userObjectId !== null && userObjectId !== undefined) {
          if (
            zauthTokenVal === null ||
            zauthTokenVal === undefined ||
            zauthTokenVal === ""
          ) {
            getAzureEntity(theme);
          } else {
            fetchNotifications(
              zauthTokenVal,
              env,
              dsso,
              deviceId,
              email,
              {
                theme: theme,
                mstJson: mstJson,
              },
              selUrl
            );
          }
        }
      });
    })
    //  fetchNotifications('5fa876e2-5755-4850-ab2e-5e178d723649');
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // userObjectId = "d3555172-a0e7-4251-abb0-8dc9cc2a572b"; //context?.user?.id;
    // console.log("userObjectId", userObjectId);
    // if (userObjectId !== null && userObjectId !== undefined) {
    //   if (
    //     zauthTokenVal === null ||
    //     zauthTokenVal === undefined ||
    //     zauthTokenVal === ""
    //   ) {
    //     getAzureEntity(theme);
    //     setWitdhAdj(true) ;
    //   } else {
    //     fetchNotifications(
    //               zauthTokenVal,
    //               env,
    //               dsso,
    //               deviceId,
    //               email,
    //               {
    //                 theme: theme,
    //                 mstJson: mstJson,
    //               },
    //               selUrl
    //             );
    //   }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  // const jsonDataArr = JsonData;
  // jsonDataArr.forEach(item=>{
  //   item['timeStamp'] = formatDate(new Date(item.timeStamp))
  // })

  const sendProactiveMessage = async (
    adaptiveCard: { type: string; msTeams: { width: string; }; body: { type: string; size: string; weight: string; text: string; wrap: boolean; }[]; $schema: string; version: string; },
    adaptiveCardData: { message: any; },
    zauthToken: any,
    conversationRef: string
  ) => {
    // const URL = "http://localhost:3978/api/sendProactiveNotif";
    console.log(conversationRef);
    const authObject = new authStore();

    const URL=process.env.REACT_APP_ContainerBotUrl

    //const URL = `https://${authObject.bot}.azurewebsites.net/api/sendProactiveNotif`;
    const data = {
      conversationReference: JSON.parse(conversationRef),
      adaptivecard: adaptiveCard,
      adaptiveCardData: adaptiveCardData,
      bot:authObject.bot
    };
    console.log(data);
    const response = await postCall(
      URL,
      "",
      {
        "Content-Type": "application/json",
      },
      data
    );
    console.log("response", response);
    setIsLoading(false);
    if (response.data.statusCode === 200) {
      if (response !== undefined && response !== null) {
        appInsights.trackEvent({
          name: "TabtoChatCompleted",
          properties: { zauthToken },
        });
      } else {
      }
    }
  };

  const getProductAdaptiveCard = async ( item: { notificationEntity: any; product: any; entityNumber: any; notificationStatus: string; },authObject:any) => {
    console.log("all item",item);

    let notificationEntity = item.notificationEntity;
    let productName = item.product;
    let key = "";
    switch (notificationEntity) {
      case "PURCHASE ORDER":
        notificationEntity = "purchaseOrder";
        key = "purchaseOrderNumber";
        break;
      case "REQUEST":
        notificationEntity = "requests";
        key = "requestNumber";
        break;
      case "REQUISITION":
        notificationEntity = "requisition";
        key = "requisitionNo";
        break;
      case "INVOICE":
        notificationEntity = "invoice";
        key = "entityNumber";
        break;
      case "CONTRACT":
      case "AUTHORING REVIEW":
        notificationEntity = "contract::authoring";
        key = "CONTRACT_NUMBER";
        break;
      case "SIGNOFF REVIEW":
        notificationEntity = "contract::signoff";
        key = "CONTRACT_NUMBER";
        break;
      case "PENDING REVIEW":
        notificationEntity = "contract::review";
        key = "CONTRACT_NUMBER";
        break;
      case "PENDING PUBLISH":
        notificationEntity = "contract::publish";
        key = "CONTRACT_NUMBER";
        break;
      case "SUPPLIER":
        notificationEntity = "supplier_request";
        key = "requestNumber";
        break;
      case "EVENT PUBLISH":
        notificationEntity = "fullSource";
        key = "EventuiId";
        break;
      default:
        notificationEntity = "requisition";
        key = "requisitionNo";

        break;
    }
    let baseURL: any ;
    const resp = await getSelectedEnv(mstKeys);
    if (resp ) {
       baseURL =  await getBaseUrl(resp);
    }
    const url = baseURL;
    console.log("BassssseUrl",url)
   const  {zauthToken,conversationRef} = await getToken(userObjectId);
   console.log("zauthToken",zauthToken)

   appInsights.trackEvent({ name: "See Actions", properties: { zauthToken } });

    // const path = dsso
    //   ? URL.BASE_MERLIN_ASSIST_DSSO_URL
    //   : URL.BASE_MERLIN_ASSIST_URL;
    // const details = {
    //   deviceId: deviceId && deviceId.toString(),
    //   emailAddress: email,
    // };
    // const header = dsso
    //   ? getHeaderswithApprovalList(zauthToken, details)
    //   : getHeaderswithApprovalList(zauthToken, null);
    const path = URL.BASE_MERLIN_ASSIST_URL;

    const header = getHeaderswithApprovalList(zauthToken, null);
    header.aadObjectId = userObjectId;
    header.botname = authObject.botname;
    const data = JSON.stringify({
      criteria: [
        {
          key: key,
          value: item.entityNumber,
          multivalue: [],
          isRange: false,
          operator: "CONTAINS",
          priority: 1,
        },
      ],
    });
    let isapprovallist = false;

     if (item.notificationStatus === "PENDING") {
      isapprovallist = true;
    } else if (item.notificationStatus !== "PENDING" && notificationEntity.includes("contract")) {
      isapprovallist = false;
      notificationEntity = "contract";
    } else if(notificationEntity === "contract::authoring") {
      isapprovallist = true;
    } else {
      isapprovallist = false;
    }
    header.isapprovallist = isapprovallist;
    console.log(item.notificationStatus + isapprovallist);
    const response = await postCall(
      url,
      path +
        `/merlinassist/advancesearch/filter?productName=${productName}&entityName=${notificationEntity}`,
      header,
      data
    );
    if (response !== undefined && response !== null) {
      const adaptiveCard = response.data.data.adaptiveCard;
      let adaptiveCardData = response.data.data.adaptiveCardPayload;
      console.log("Adaptive card", adaptiveCard);
      if(adaptiveCardData?.common && adaptiveCardData.cardData && adaptiveCardData.cardData.length > 0) {
        const commonData = adaptiveCardData.common;
        const cardData = adaptiveCardData.cardData[0];
        adaptiveCardData = {...commonData,...cardData};
      }
      if (adaptiveCard && adaptiveCard !== undefined && adaptiveCard !== null) {
        await sendProactiveMessage(adaptiveCard, adaptiveCardData, zauthToken,conversationRef);
      } else {
        if(response?.data?.data?.displayMessage)
        {
          await sendProactiveMessage(messageAdpCard, {
            "message": response?.data?.data?.message
        }, zauthToken,conversationRef);
        }
        setIsLoading(false);
      }
    } else {
      setisErrorMessage(true);
      setErrmessage(
        mstKeys.MST_Something_went_wrong_Please_try_again_after_some_time
      );
    }
  };

  const getToken = async(userObjectId: string)=>{
    const authObject = new authStore();
    var config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(JSON.stringify(config));
    const response = await getCall(config.url, "", config.headers);
    let zauthToken: any;
    let conversationRef: any;
    if (response !== undefined && response !== null) {
       zauthToken = response.data && response.data.zycusAuthToken;
       conversationRef =response.data && response.data.conversationReference
    }
    return {zauthToken,conversationRef};
  }
  const getAzureEntity = async (theme: any) => {
    console.log("accountname", process.env.accountName);
    app.notifySuccess()
    const authObject = new authStore();
    var config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(JSON.stringify(config));
    const response = await getCall(config.url, "", config.headers);
    if (response !== undefined && response !== null) {
      const zauthToken = response.data && response.data.zycusAuthToken;
      const deviceId = response.data && response.data.deviceId;
      const emailAddress = response.data && response.data.emailAddress;
      mstKeys = { ...mstKeys, ...getMstKeys(response?.data?.mstKeyJson) };
      // mstKeys = getMstKeys(mstKeys);
      console.log("zauthToken", zauthToken);
      setDeviceId(deviceId);
      setEmail(emailAddress);
      setzauthToken(zauthToken);
      setMstKeysJson(mstKeys);

      const dateString = `<span>${
        mstKeys && mstKeys.MST_Date_and_Time
      }`.concat(" <img max-width='2%' src='" + filter + "'/>");
      const dateHtmlString = <div>{parse(dateString)}</div>;
      const documentNumberString = `<span> ${
        mstKeys && mstKeys.MST_ID
      } / ${mstKeys && mstKeys.MST_Description}`.concat(
        " <img max-width='2%' src='" + filter + "'/>"
      );
      const documentNumberHtmlString = <div>{parse(documentNumberString)}</div>;
      // const docmentTitleString = "<span>Document Title".concat(
      //   " <img max-width='2%' src='" + filter + "'/>"
      // );
      // const docmentTitleHtmlString = <div>{parse(docmentTitleString)}</div>;
      const notifMessageString = `<span> ${
        mstKeys && mstKeys.MST_Notification
      } `;
      const notifMessageHtmlString = (
        <div>
          <FontAwesomeIcon icon={faAddressCard} />
          {parse(notifMessageString)}
        </div>
      );
      if(isMobile === "android" || isMobile === "ios" || isMobile === "teamsRoomsAndroid" || isMobile === "teamsPhones")
      {
        setColumns([
          {
            key: "notificationTextMessage",
            name: "Notification",
            text: notifMessageHtmlString,
            editable: false,
            dataType: "string",
            minWidth: 360,
            maxWidth: 360,
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true,
            disableSort: false,
            onRender: (item: { notificationEntity: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; requestId: string; entityNumber: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; entityTitle: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; notificationTextMessage: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; timeStampString: string; timeStamp: string | number | Date; product: string; }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={textRowDocumentID}
                  onClick={async() => {
                    let baseURL: any;
                    const resp = await getSelectedEnv(mstKeys);
                    if (resp ) {
                       baseURL =  await getBaseUrl(resp);
                    }

                    let notificationUrl = baseURL
                    let url: string | URL | undefined;
                    url = await getRedirectionUrl(notificationUrl, item);
                    console.log("Redirection URL :",url);
                    // switch (item.notificationEntity) {
                    //   case "PURCHASE ORDER":
                    //     url =
                    //       notificationUrl +
                    //       "/eproc/purchaseorder/view/" +
                    //       item.requestId;
                    //     break;
                    //   case "REQUEST":
                    //     url = notificationUrl + "/irequest/listing/all-requests";
                    //     break;
                    //   case "REQUISITION":
                    //     url =
                    //       notificationUrl +
                    //       "/eproc/requisition/requisition-view/" +
                    //       item.requestId;
                    //     break;
                    //   case "INVOICE":
                    //     url = notificationUrl + "/einvoice/home/invoice/list";
                    //     break;
                    //   case "CONTRACT":
                    //   case "AUTHORING REVIEW":
                    //     url =
                    //       notificationUrl + "icontract/icontract/home/authoring/list";
                    //     break;
                    //   case "SUPPLIER":
                    //     url =
                    //       notificationUrl +
                    //       "/isupplier/request-management/all-requests";
                    //     break;
                    //   default:
                    //     url = notificationUrl;
                    //     break;
                    // }
                    if(isMobile === "android" || isMobile === "ios" || isMobile === "teamsRoomsAndroid" || isMobile === "teamsPhones")
                    {
                      return;
                    }
                    window.open(url, "_blank", "noreferrer");
                  }}
                >
                  {item.entityNumber}
                </div>
                <div style={idStyle}>{item.entityTitle}</div>
                <div>
                  <button style={notificationButtonStyle}>
                    {item.notificationTag || item.notificationEntity}
                  </button>
                </div>
                <div style={notificationTextStyle}>
                  {item.notificationTextMessage}
                </div>
                <div style={textRow1}>{item.timeStampString.substr(0, 11)}</div>
                <div style={textRow2}>
                  {new Date(item.timeStamp)?.toLocaleString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
                {item.product.toLowerCase()!=="isource" && !(isMobile === "android" || isMobile === "ios" || isMobile === "teamsRoomsAndroid" || isMobile === "teamsPhones")?<button
                  style={buttonStyle}
                  onClick={async () => {
                    /**
                     * API call
                     * sendProactiveMessage()
                     */
                    const authObject = new authStore();
                    setIsLoading(true);
                    await getProductAdaptiveCard( item,authObject);
                    if (pages.isSupported()) {
                      // @ts-nocheck
                      try{
                        const navPromise = pages.navigateToApp({
                          appId: authObject.appId,
                          pageId: ""
                        });
                        navPromise
                          .then((result) => {
                            console.log(result);
                          })
                          .catch((error) => {
                            alert("Only applicable on Microsoft teams")
                            console.log(error);
                          });
                      }catch(err)
                      {
                          alert("Only applicable on Microsoft teams")
                          console.log(err);
                      }

                    }
                  }}
                >
                 {mstKeys && mstKeys.MST_VIEW_DETAILS}
                </button>:null}
              </div>
              // <> <Button style={notificationButtonStyle}> {item.notificationEntity}</Button> <>{item.notificationEntity}</></>
            ),
          }
        ]);
      }
      else{
        setColumns([
          {
            key: "timeStampString",
            name: "Date & Time",
            text: dateHtmlString,
            editable: false,
            dataType: "string",
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            inputType: EditControlType.DateTime,
            disableSort: false,
            applyColumnFilter: true,
            iconName: "IconTest",
            onRender: (item: { timeStampString: string; timeStamp: string | number | Date; }) => (
              <div>
                <div style={textRow1}>{item.timeStampString.substr(0, 11)}</div>
                <div style={textRow2}>
                  {new Date(item.timeStamp)?.toLocaleString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
              // <> <Button style={notificationButtonStyle}> {item.notificationEntity}</Button> <>{item.notificationEntity}</></>
            ),
          },
          {
            key: "entityNumber",
            name: "ID/Description",
            text: documentNumberHtmlString,
            editable: false,
            dataType: "string",
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true,
            disableSort: false,
            inputType: EditControlType.TextField,
            pickerOptions: {
              pickerTags: ["entityNumber"],
              minCharLimitForSuggestions: 2,
              tagsLimit: 1,
              pickerDescriptionOptions: {
                enabled: true,
                values: [{ key: "entityNumber", description: "entityTitle" }],
              },
              suggestionsRule: StringOperators.STARTSWITH,
            },
            onRender: (item: { notificationEntity: any; requestId: string; entityNumber: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; entityTitle: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
              <div>
                <div
                  style={textRowDocumentID}
                  onClick={async() => {
                    let baseURL: any ;
                    const resp = await getSelectedEnv(mstKeys);
                    if (resp ) {
                       baseURL =  await getBaseUrlForAllProduct(resp);
                    }
                    let notificationUrl =baseURL ;
                    let url: string | URL | undefined;
                    url = await getRedirectionUrl(notificationUrl, item);
                    console.log("Redirection URL :",url);
                    // switch (item.notificationEntity) {
                    //   case "PURCHASE ORDER":
                    //     url =
                    //       notificationUrl +
                    //       "eproc/purchaseorder/view/" +
                    //       item.requestId;
                    //     break;
                    //   case "REQUEST":
                    //     url = notificationUrl + "irequest/listing/all-requests";
                    //     break;
                    //   case "REQUISITION":
                    //     url =
                    //       notificationUrl +
                    //       "eproc/requisition/requisition-view/" +
                    //       item.requestId;
                    //     break;
                    //   case "INVOICE":
                    //     url = notificationUrl + "einvoice/home/invoice/list";
                    //     break;
                    //   case "CONTRACT":
                    //   case "AUTHORING REVIEW":
                    //     url =
                    //       notificationUrl + "icontract/icontract/home/authoring/list";
                    //     break;
                    //   case "SUPPLIER":
                    //     url =
                    //       notificationUrl +
                    //       "isupplier/request-management/all-requests";
                    //     break;
                    //   default:
                    //     url = notificationUrl;
                    //     break;
                    // }
                      const redirectionUrl = authObject.ssoRedirection + zauthToken + "&" + url;
                      if(isMobile === "android" || isMobile === "ios" || isMobile === "teamsRoomsAndroid" || isMobile === "teamsPhones")
                      {
                        return;
                      }
                    window.open(redirectionUrl, "_blank", "noreferrer");
                  }}
                >
                  {item.entityNumber}
                </div>
                <div style={idStyle}>{item.entityTitle}</div>
              </div>
              // <> <Button style={notificationButtonStyle}> {item.notificationEntity}</Button> <>{item.notificationEntity}</></>
            ),
          },
          {
            key: "notificationTextMessage",
            name: "Notification",
            text: notifMessageHtmlString,
            editable: false,
            dataType: "string",
            minWidth: 360,
            maxWidth: 360,
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true,
            disableSort: false,
            onRender: (item: { notificationEntity: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; notificationTextMessage: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <button style={notificationButtonStyle}>
                    {item.notificationTag || item.notificationEntity}
                  </button>
                </div>
                <div style={notificationTextStyle}>
                  {/* {item.notificationTextMessage} */}
                  {item?.notificationTextMessage.includes("undefined") ? item?.notificationTextMessage.slice(0, item.notificationTextMessage.indexOf(" by undefined")) : item.notificationTextMessage}
                </div>
              </div>
              // <> <Button style={notificationButtonStyle}> {item.notificationEntity}</Button> <>{item.notificationEntity}</></>
            ),
          },
          {
            key: "action",
            name: "",
            text: "",
            editable: false,
            dataType: "string",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            includeColumnInExport: true,
            includeColumnInSearch: true,
            applyColumnFilter: true,
            disableSort: false,

            onRender: (item: { product: string; }) => (
              <div>
                {item.product.toLowerCase()!=="isource"?<button
                  style={buttonStyle}
                  onClick={async () => {
                    /**
                     * API call
                     * sendProactiveMessage()
                     */
                    const authObject = new authStore();
                    setIsLoading(true);
                    await getProductAdaptiveCard( item,authObject);
                    if (pages.isSupported()) {
                      // @ts-nocheck
                      try{
                        const navPromise = pages.navigateToApp({
                          appId: authObject.appId,
                        });
                        navPromise
                          .then((result) => {
                            console.log(result);
                          })
                          .catch((error) => {
                            alert("Only applicable on Microsoft teams")
                            console.log(error);
                          });
                      }catch(err)
                      {
                          alert("Only applicable on Microsoft teams")
                          console.log(err);
                      }

                    }
                  }}
                >
                 {mstKeys && mstKeys.MST_VIEW_DETAILS}
                </button>:null}
              </div>
            ),
          },
        ]);
      }

      appInsights.trackEvent({
        name: "NotificationTab",
        properties: { emailAddress, zauthToken },
      });

      let baseURL: React.SetStateAction<string>;
      if (zauthToken) {
        const data = await getSelectedEnv(mstKeys);
        if (data && data !== undefined) {
          setEnv(data.selectedEnv);
          baseURL = await getBaseUrl(data);
          setSelUrl(baseURL);
          setIsdsso(JSON.parse(data.selectedTenant).tenantIsDSSO);
        }
      //  const error = await tenantAndUserCommon(data,setisErrorMessage,setErrmessage,userObjectId,"notificationtabaccess");
      //   //check user level access
      //   if(error)
      //   {
      //     return;
      //   }

        fetchNotifications(
          zauthToken,
          data.selectedEnv,
          JSON.parse(data.selectedTenant).tenantIsDSSO,
          deviceId,
          emailAddress,
          {
            theme: theme,
            mstJson: mstKeys,
          },
          baseURL
        );
      } else {
        setisErrorMessage(true);
        setErrmessage(Strings.MST_INVALID_TOKEN_NOTIFICATION);
      }
    } else {
      setisErrorMessage(true);
      setErrmessage(
        mstKeys.MST_Something_went_wrong_Please_try_again_after_some_time
      );
    }
  };

  const getRedirectionUrl = async(notificationUrl:string, { notificationEntity, requestId, notificationStatus,entityId } : any) => {
    switch (notificationEntity) {
        case "PURCHASE ORDER":
            return notificationUrl + "eproc/purchaseorder/view/" + requestId;
        case "REQUEST":
            if(notificationStatus === "PENDING") {
                return  notificationUrl + "irequest/listing/my-requests";
            } else {
                return  notificationUrl + "irequest/to-dos/approvals";
            }
        case "REQUISITION":
            return notificationUrl + "eproc/requisition/requisition-view/" + requestId;
        case "INVOICE":
            return notificationUrl + "einvoice/invoice/view/" + entityId;
        case "CONTRACT":
        case "AUTHORING REVIEW":
            return notificationUrl + "icontract/icontract/authoring/contract/" + requestId + "/details";
        case "SUPPLIER":
            return  notificationUrl + "isupplier/request-management/all-requests";
        case "EVENT_PUBLISH":
        case "EVENT_AWARD":
            if(notificationStatus === "PENDING") {
                return  notificationUrl + "isource/approval/list";
            } else {
                return  notificationUrl + "isource/events";
            }
        default:
          return notificationUrl;
      }
  }
  const getBaseUrlForAllProduct= async(data: { selectedSetupUrl: string; })=>{
    let baseURL = data.selectedSetupUrl.substring(
      0,
      data.selectedSetupUrl.indexOf("home")
    );
    if (
      baseURL !== undefined &&
      baseURL !== ""
    ) {
      if (baseURL.includes("http://")) {
        baseURL =
          baseURL.replace(/^http:\/\//i, "https://")
      }
    } else {
      baseURL = data.selectedSetupUrl + "/";
    }
    return baseURL;
  }

  const getBaseUrl= async(data: { selectedSetupUrl: string; })=>{
    let baseURL = data.selectedSetupUrl.substring(
      0,
      data.selectedSetupUrl.indexOf("home")
    );
    if (
      baseURL !== undefined &&
      baseURL !== undefined &&
      baseURL !== ""
    ) {
      if (baseURL.includes("http://")) {
        baseURL =
          baseURL.replace(/^http:\/\//i, "https://") +
          "merlinassistadaptor/api/";
      } else {
        baseURL = baseURL + "merlinassistadaptor/api/";
      }
    } else {
      baseURL = data.selectedSetupUrl + "/";
    }
    return baseURL;
  }


  const getSelectedEnv = async (mstKeys: { MST_Please_Go_to_Chat_Tab_and_Login?: string; MST_Something_went_wrong_Please_try_again_after_some_time: any; MST_Hang_on_while_we_get_things_set_up_for_you?: string; MST_LoadinginProgress?: string; MST_You_have_no_notification_at_this_moment?: string; MST_Date_and_Time?: string; MST_ID?: string; MST_Description?: string; MST_Notification?: string; MST_VIEW_DETAILS?: string; MST_Search_in_notification?: string; MST_INVALID_TOKEN_NOTIFICATION?: string; MST_INVALID_TOKEN_COLLAB?: string; COLLAB_ONLY_IN_WEB?: string; COLLAB_ACCESS?: string; }) => {
    const authObject = new authStore();
    var config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.getSelectedEnvTable}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    //console.log(JSON.stringify(config));
    const response = await getCall(config.url, "", config.headers);
    if (response !== undefined && response !== null) {
      return response.data;
    } else {
      setisErrorMessage(true);
      setErrmessage(
        mstKeys.MST_Something_went_wrong_Please_try_again_after_some_time
      );
    }
  };

  const nextPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNo(value);
    setIsLoading(true);
  };



  const fetchNotifications = async (
    zauthToken: string,
    selectedEnv: string,
    tenantIsDSSO: boolean,
    deviceId: string,
    emailAddress: string,
    theme: { theme: any; mstJson?: { MST_Please_Go_to_Chat_Tab_and_Login: string; MST_Something_went_wrong_Please_try_again_after_some_time: string; MST_Hang_on_while_we_get_things_set_up_for_you: string; MST_LoadinginProgress: string; MST_You_have_no_notification_at_this_moment: string; MST_Date_and_Time: string; MST_ID: string; MST_Description: string; MST_Notification: string; MST_VIEW_DETAILS: string; MST_Search_in_notification: string; MST_INVALID_TOKEN_NOTIFICATION: string; MST_INVALID_TOKEN_COLLAB: string; COLLAB_ONLY_IN_WEB: string; COLLAB_ACCESS: string; } | { MST_Date_and_Time: string; MST_ID: string; MST_Description: string; }; },
    baseURL: string
  ) => {
    setAppTheme(theme.theme);
    console.log("selUrl :", selUrl);
    console.log("baseURL :", baseURL);
    const url = selUrl ? selUrl : baseURL;
    // const path = tenantIsDSSO
    //   ? URL.BASE_MERLIN_ASSIST_DSSO_URL
    //   : URL.BASE_MERLIN_ASSIST_URL;
    // const details = {
    //   deviceId: deviceId && deviceId.toString(),
    //   emailAddress: emailAddress,
    // };
    // const header = tenantIsDSSO
    // ? getHeaders(zauthToken, details)
    // : getHeaders(zauthToken, null);
    const path = URL.BASE_MERLIN_ASSIST_URL;

    const header = getHeaders(zauthToken, null);
    var data = JSON.stringify({
      pageNo: pageNo,
      perPageRecords: 20,
      sorts: [
        {
          fieldName: "timeStamp",
          direction: "DESC",
        },
      ],
    });

    const response = await postCall(url, path + URL.NOTIFICATION, header, data);
    if (response.data.statusCode === 200) {
      if (response !== undefined && response !== null) {
        let records = response?.data?.data?.result;
        let totalRecords = 0;
        await getFormatedData(records,{...mstKeys,...mstJson}).then((res) => {
          if (res && res.length > 0) {
            console.log(res);
          } else {
            res = [];
          }
          setItems(res);
          setOriginalItemsList(res);
          totalRecords =
          totalRecords = response?.data?.data?.pagination?.totalRecords >= 100
              ? 100
              :totalRecords = response?.data?.data?.pagination?.totalRecords;
          setTotalRecords(totalRecords);
          setIsLoading(false);
        });
      } else {
        setisErrorMessage(true);
        setErrmessage(
          mstKeys.MST_Something_went_wrong_Please_try_again_after_some_time
        );
        setzauthToken("");
      }
    } else if (response.data.statusCode === 401) {
      setisErrorMessage(true);
      setErrmessage(Strings.MST_INVALID_TOKEN_NOTIFICATION);
      setzauthToken("");
    } else {
      setisErrorMessage(true);
      setErrmessage(
        mstKeys.MST_Something_went_wrong_Please_try_again_after_some_time
      );
      setzauthToken("");
    }
    app.notifySuccess()
  };
console.log("witdhAdj",witdhAdj)
  return (
    <ThemeProvider style={{ background: "inherit" }}>
      {/* <div>
        <p>Z auth token {zauthTokenVal}</p>
      </div> */}

      {!isErrorMessage  ? (
        <Fabric className={classNames.gridStyle}>
          {!isLoading && items.length && !witdhAdj ? (
            <div>
              <div className={classNames.controlWrapper}>
                <TextField
                  placeholder={mstJson && mstJson.MST_Search_in_notification}
                  className={mergeStyles({
                    width: "60vh",
                    paddingBottom: "5px",
                    marginLeft: "6vh",
                    paddingTop: "10px",
                  })}
                  onChange={(event) =>
                    EventEmitter.dispatch(EventType.onSearch, event)
                  }
                />
              </div>
            </div>
          ) : null}

          {isLoading ? (
            <div className="parent">
              <img
                className="msteamsIcon"
                src={msteamsIcon}
                alt="Microsoft teams"
              />
              <div>
                {isLoading ? (
                  <span className="progressBar">
                    <ProgressBar
                      height="50"
                      width="50"
                      ariaLabel="progress-bar-loading"
                      wrapperStyle={{ width: "180px" }}
                      wrapperClass="progress-bar-wrapper"
                      borderColor="#7B83EB"
                      barColor="#464EB8"
                    />
                    <p style={{ margin: 0, fontSize: 12, marginLeft: 280 }}>
                      {mstJson.MST_LoadinginProgress}
                    </p>
                  </span>
                ) : null}
              </div>
            </div>
          ) : null}
          <div style={!witdhAdj ? scrollHorizontal : scrollMobile}>
            {/* {isLoading ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={[
                  "#7B83EB",
                  "#505AC9",
                  " #464EB8",
                  "#7B83EB",
                  "#7B83EB",
                ]}
              />
            ) : null} */}
            {/* {items.length ? ( */}

            {
              <EditableGrid
                className={classNames.headerStyle}
                id={1}
                columns={columns}
                items={items}
                height={items.length > 0 ? "100vh" : "20vh"}
                width={!witdhAdj?"950px":"100%"}
                position={"relative"}
                enableColumnFilters={true}
                enableRowAddWithValues={{
                  enable: false,
                  enableRowsCounterInPanel: false,
                }}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                onRenderRow={(props, defaultRender) => {
                  if (props && defaultRender) {
                    return defaultRender({
                      ...props,
                      styles: {
                        ...props.styles,
                        root: {
                          display: "block",
                          overflow: "auto",
                          float:'left',
                          background: "hsl(0deg 0% 97%)",
                          // borderCollapse: "collapse",
                          borderTop: "0.1px solid #c4c3be",
                          "&:hover": {
                            backgroundColor: "#dce8fc",
                            boxShadow: "inset 3px 0 0 0 #5e63f7",
                          },
                        },
                      },
                    });
                  }
                  return null;
                }}
              />
            }

            {/* ) : null} */}

            {items.length ? (
              <div style={{ padding: 20 }}>
                <Paginate
                  size="small"
                  onChange={nextPage}
                  count={witdhAdj?3:Math.ceil(totalRecords / 20)}
                  variant="outlined"
                  shape="rounded"
                  hidePrevButton
                  hideNextButton
                />{" "}
              </div>
            ) : (
              <div style={{ padding: 20 }}>
                {isLoading ? (
                  <div style={appTheme === "dark" ? { color: "white" } : null}>
                    {mstJson && mstJson.MST_LoadinginProgress}
                  </div>
                ) : (
                  <div style={appTheme === "dark" ? { color: "white" } : null}>
                    {mstJson &&
                      mstJson.MST_You_have_no_notification_at_this_moment}
                  </div>
                )}
              </div>
            )}
          </div>
        </Fabric>
      ) : (
        <div>
          <div>
            {errmessage ? errmessage:""}
        {/* { Strings.MST_INVALID_TOKEN_NOTIFICATION.split("\n").map((t,key) => {
            return <h4 style={{fontSize:15}} key={key}>{t}</h4>;
        })} */}
    </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default JsonDataDisplay;
