import STRINGS from "./pages/Strings";
import authStore from "./Keys";
import { getCall, postCall } from "./Api/services";
import { getHeaderwithContentType } from "./Headers";

export async function tenantAndUserCommon(data,setisErrorMessage,setErrmessage,userObjectId,type)
{
   //call to check access for tenant accsss
   let error=false;
   const tenantData =  await checkTenantLevelAccess();
   let tenantSelected = {
    notificationtabaccess:""
   }
   if(tenantData)
   {
    const tenantToCheck = JSON.parse(data.selectedTenant).tenantId;
    let tenantPresent =false; 
    tenantData.forEach((tenant)=>{
      if((tenant.partitionKey === tenantToCheck))
      {
        tenantPresent =true;
        tenantSelected=tenant;
      }
    })
   if(!tenantPresent)
   {
    error=true;
    setisErrorMessage(true);
    setErrmessage("Tenant level access is not present.")
    return error;
   }
   else{
    if(!(tenantSelected[type]))
    {
      error=true;
      setisErrorMessage(true);
      setErrmessage("Tenant level access is not present.")
      return error;
    }
   }
   }
    const userData =   await checkUserLevelAccess(userObjectId);
    if(userData)
    {
      if(!(userData[type]))
      {
        error=true;
        setisErrorMessage(true);
        setErrmessage("User level access is not present.")
        return error;
      }
    }
    return error;
}

export async function checkTenantLevelAccess()
{
  const authObject = new authStore();

  const response = await getCall(authObject.checkTenantAccess,"/getTenant", getHeaderwithContentType());
  return response.data;

}

export async function checkUserLevelAccess(aadObjectId){
  const authObject = new authStore();

  const response = await postCall(authObject.checkTenantAccess,"/getUserAccess",getHeaderwithContentType(),{
    "aadObjectId":aadObjectId
});
  return response.data;
}

export function getMstKeys(mstKeys){
  mstKeys =mstKeys ?JSON.parse(mstKeys):STRINGS;
  return mstKeys;
}

export function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

 export function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  }

  export function getNotificationMessage(item,mstKeys){
    let notificationString = ''
    if(item.notificationStatus === notificationMessage.APPROVED){
      notificationString = mstKeys.MST_Document_has_been_actionName_by_actorName;
      notificationString = notificationString.replace("{{actionName}}",mstKeys?.MST_Approved).replace("{{actorName}}",item?.lastActionDetails?.actorName.toLowerCase());
    } else if(item.notificationStatus === notificationMessage.REJECTED){
      notificationString = mstKeys.MST_Document_has_been_actionName_by_actorName;
      notificationString = notificationString.replace("{{actionName}}",mstKeys?.MST_Rejected).replace("{{actorName}}",item?.lastActionDetails?.actorName.toLowerCase());
    } else if(item.notificationStatus === notificationMessage.DELEGATED){
      notificationString = mstKeys.MST_Document_has_been_actionName_by_actorName;
      notificationString = notificationString.replace("{{actionName}}",mstKeys?.MST_Delegated).replace("{{actorName}}",item?.lastActionDetails?.actorName.toLowerCase());
    } else if(item.notificationStatus === notificationMessage.WITHDRAWN || item.notificationStatus === notificationMessage.REVIEWED ||item.notificationStatus === notificationMessage.RECALLED ){
      notificationString = mstKeys.MST_Document_has_been_actionName_by_actorName;
      notificationString = notificationString.replace("{{actionName}}",item.notificationStatus.toLowerCase()).replace("{{actorName}}",item?.lastActionDetails?.actorName.toLowerCase());
    } else if(item.notificationStatus === notificationMessage.PENDING){
      notificationString = mstKeys.MST_You_have_a_document_pending_for_approval;
    } else {
      notificationString ="";
    }
    return notificationString;
  }

export function getNotificationTags(item, mstKeys) {
  const entityToMstKeyMap = {
    REQUISITION: 'MST_Requisition',
    'PURCHASE ORDER': 'MST_PurchaseOrder',
    'PURCHASE ORDER AMEND': 'MST_PurchaseOrder',
    PO: 'MST_PurchaseOrder',
    REQUEST: 'MST_Request',
    INVOICE: 'MST_Invoice',
    CONTRACT: 'MST_Contract',
    CLAUSE_REVIEW: 'MST_Contract',
    PENDING_REVIEW: ['MST_Pending', 'MST_Review'],
    SIGNOFF_REVIEW: ['MST_Signoff', 'MST_Review'],
    AUTHORING_REVIEW: ['MST_Authoring', 'MST_Review'],
    'EVENT PUBLISH': ['MST_Event', 'MST_Publish'],
    'EVENT AWARD': ['MST_Event', 'MST_Award'],
  };
  const entity = item.notificationEntity.toUpperCase();
  const mstKey = entityToMstKeyMap[entity] || entity;
  const mstKeyArray = Array.isArray(mstKey) ? mstKey : [mstKey];
  const notificationTag = mstKeyArray.map((key) => mstKeys?.[key]).filter(Boolean).join(' ');
  return notificationTag;
}
  export const notificationMessage={
    APPROVED:'APPROVED',
    REJECTED: 'REJECTED',  
    WITHDRAWN: 'WITHDRAWN',
    DELEGATED: 'DELEGATED', 
    REVIEWED: 'REVIEWED',
    RECALLED: 'RECALLED',
    PENDING : 'PENDING'
  }

  export function formatTimestamp(timestamp) {
    const date = new Date(timestamp); 
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours % 12 || 12; 
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes; 
    const period = hours < 12 ? 'AM' : 'PM';
    const formattedTime = `${formattedHours}:${formattedMinutes}${period}`;
    return formattedTime;
}