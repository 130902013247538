import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Notifications from "./pages/Notifications";
import AppContent from "./pages/AppContent";
import AssignLocation from "./pages/AssignLocation";
import CustomDomain from "./pages/CustomDomain";
import Collaboration from "./pages/Collaboration";
import CollabAssign from "./components/CollabAssign";
import TestCollab from "./components/TestCollab";
import Collabmessage from "./components/collabmessage";
import SetCookieForDewdrops from "./components/setCookieForDewdrops";
import ErrorBoundary from './error/ErrorBoundary.js';

function App() {
  console.log("REACT_APP_BUILD_NO",process.env.REACT_APP_BUILD_NO);
  console.log("accountName",process.env.REACT_APP_accountName);
  console.log("process.env.accessKey",process.env.REACT_APP_accessKey);
  console.log("process.env.token",process.env.REACT_APP_token);
  console.log("process.env.tableName",process.env.REACT_APP_tableName);
  console.log("process.env.getSelectedEnvTable",process.env.REACT_APP_getSelectedEnvTable);
  console.log("process.env.bot",process.env.REACT_APP_bot);
  console.log("appId",process.env.REACT_APP_appId);
  console.log("process.env.botname",process.env.REACT_APP_botname);

  return (
    <ErrorBoundary>
    <BrowserRouter>
      <div className="App">
        {/* <ul className="App-header">
           <li>
             <Link to="/">Home</Link>
           </li>
           <li>
             <Link to="/notifications">Notifications</Link>
           </li>
         </ul> */}
        <Routes>
          <Route path="/" element={<Notifications />}></Route>
          {/* <Route exact path=':url' element={< OpenCustomDomainPage />}></Route> */}

          {/*Custom Domain Route */}
          <Route exact path=":url" element={<CustomDomain />}></Route>
          <Route
            exact
            path="/assignlocation"
            element={<AssignLocation />}
          ></Route>
          {/*Notification Route */}
          <Route
            exact
            path="/notifications"
            element={<Notifications />}
          ></Route>
          <Route
            exact
            path="/settings"
            element={<Notifications />}
          ></Route>
          <Route exact path="/app" element={<AppContent />}></Route>
          {/* <Route exact path='/apps' element={< Apps />}></Route> */}
          {/* <Route exact path='/vendorApp' element={< VendorApps />}></Route> */}
          {/*Collaboration Routes  */}
          <Route exact path="/Collab" element={<TestCollab />}></Route>
          <Route exact path="/collabAssign" element={<CollabAssign />}></Route>
          <Route
            exact
            path="/CollabMessage"
            element={<Collabmessage />}
          ></Route>
          <Route
            exact
            path="/collaboration"
            element={<Collaboration />}
          ></Route>

          <Route
            exact
            path="/redirect/main"
            element={<SetCookieForDewdrops />}
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
