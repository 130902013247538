import { formatDate, getNotificationMessage, getNotificationTags } from "../Common";

export const getFormatedData = async (data,mstKeys)=>{
    let result = data;
    // let notificationString  = ''

    result && result.forEach(item => {
        item["timeStampString"] = formatDate(new Date(item.timeStamp));
        item['notificationTextMessage'] = getNotificationMessage(item,mstKeys);
        item['notificationTag'] = getNotificationTags(item,mstKeys);
    });

    return result;
}