import JsonDataDisplay from "../JsonFormatter.tsx";
import withAppInsights from "../AppInsights";

function Notifications() {
  console.log("Notification");
  return (
    <div>
      <JsonDataDisplay />
    </div>
  );
}
export default withAppInsights(Notifications);
