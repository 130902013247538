
class AccessKeys {
    constructor() {
        this.accountName = process.env.REACT_APP_accountName;
        this.accessKey =process.env.REACT_APP_accessKey;
        this.token =process.env.REACT_APP_token;
        this.tableName =process.env.REACT_APP_tableName;
        this.getSelectedEnvTable =process.env.REACT_APP_getSelectedEnvTable;
        this.bot =process.env.REACT_APP_bot;
        this.appId = process.env.REACT_APP_appId;
        this.redirectUrl =process.env.REACT_APP_redirectUrl;
        this.InstrumentationKey = process.env.REACT_APP_InstrumentationKey;
        this.InstrumentationKeyConnectionstring =process.env.REACT_APP_InstrumentationKeyConnectionstring;                                                     
        this.checkTenantAccess =process.env.REACT_APP_checkTenantAccess;
        this.botname =process.env.REACT_APP_botname;
        this.showConsoleLogs = process.env.REACT_APP_showConsoleLogs;
        this.ssoRedirection=process.env.REACT_APP_ssoRedirection;
    }
}

export default AccessKeys;