/* eslint-disable no-useless-concat */
import { ThemeProvider } from "@fluentui/react";
import { appInsights } from "../AppInsights";

import * as React from "react";
import { useState, useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { app } from "@microsoft/teams-js";
import { getCall } from "../Api/services";
import authStore from "../Keys";
import URL from "../URL";
import { getHeaders } from "../Headers";

import { getMstKeys } from "../Common";
import STRINGS from "../pages/Strings";
let mstKeysJson;

function CollaborationData ({poNumber,typeOfDoc}) {
  console.log("CollaborationData",poNumber);
  console.log("typeOfDoc",typeOfDoc);

  const [env, setEnv] = useState("");
  const [tenant, setSelectedTenant] = useState("");
  const [mstKeys, setMstKeys] = useState({"MST_Something_went_wrong_Please_try_again_after_some_time":""});

  const [dsso, setIsdsso] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [email, setEmail] = useState("");
  const [zauthTokenVal, setzauthToken] = useState("");
  const [errmessage, setErrmessage] = useState("");
  const [collaborationUrl, setCollaborationURL] = useState("");
  const [selUrl, setSelUrl] = useState("");
  const ref = React.useRef();
  const [height, setHeight] = useState('650px');

  // const onLoad = () => {
  //   setHeight(ref?.current?.contentWindow?.document?.body?.scrollHeight + 'px');
  // };
  // useEffect(() => {
  //   onLoad();
  // }, []);

  let userObjectId;
  try{
    app.initialize();
  
  }
  catch(err)
  {
  
  }

  app.getContext().then((context) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
   
  let isMobile = context?.app?.host?.clientType;
  console.log(isMobile);
      // if (
      //    isMobile === "android" || isMobile ==="ios" ||   isMobile==="teamsPhones" || isMobile==="surfaceHub" || isMobile==="teamsRoomsAndroid"
      // ) {
      //   mobileTrue=true;
      //  setErrmessage(STRINGS.COLLAB_ONLY_IN_WEB)
      // }
  });
  useEffect(() => {
    console.log("useEffect");
    try{
      app
      .getContext()
      .then((context) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        userObjectId = context?.user?.id;
        console.log("userObjectId", userObjectId);
        console.log("zauthTokenVal", zauthTokenVal);
        if (userObjectId !== null && userObjectId !== undefined) {
          if (
            zauthTokenVal === null ||
            zauthTokenVal === undefined ||
            zauthTokenVal === ""
          ) {
            getAzureEntity();
          } else {
            if (
              env !== undefined &&
              env !== null &&
              tenant !== undefined &&
              tenant !== null
            ) {
              const userRegionData = {
                selectedEnv: env,
                selectedTenant: tenant,
              };
              const data = {
                zauthTokenVal: zauthTokenVal,
                env: env,
                dsso: dsso,
                deviceId: deviceId,
                email: email,
              };
              getTeamsVendorAppDetails(data, userRegionData, zauthTokenVal, selUrl,mstKeys);
            } else {
              getAzureEntity();
            }
          }
        }
      })
      .catch(() => {
        setErrmessage(mstKeys.MST_Something_went_wrong_Please_try_again_after_some_time);
      });
    }
    catch(err)
    {
      
    }
   

//     userObjectId = "86b2694a-0ad6-44f7-902c-8c071cfa8855"; //context?.user?.id;
//     console.log("userObjectId", userObjectId);
//     if (userObjectId !== null && userObjectId !== undefined) {
//       if (zauthTokenVal === null || zauthTokenVal === undefined || zauthTokenVal ==='') {
//        getAzureEntity();
//   }
// }
  }, [poNumber]);


  const getSelectedEnv = async (mstKeysJson) => {
    const authObject = new authStore();
    var config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.getSelectedEnvTable}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await getCall(config.url, "", config.headers);
    if (response !== undefined && response !== null) {
      return response.data;
    } else {
      setErrmessage(mstKeysJson.MST_Something_went_wrong_Please_try_again_after_some_time);
    }
  };

  const getAzureEntity = async () => {
    app.notifySuccess()

    console.log("accountname", process.env.accountName);
    const authObject = new authStore();
    var config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await getCall(config.url, "", config.headers);
    if (response !== undefined && response !== null) {
      const zauthToken = response.data && response.data.zycusAuthToken;
      const deviceId = response.data && response.data.deviceId;
      const emailAddress = response.data && response.data.emailAddress;
      mstKeysJson = response?.data?.mstKeyJson;
      mstKeysJson =getMstKeys(mstKeysJson);
      setMstKeys(mstKeysJson);
      console.log("zauthToken", zauthToken);
      setDeviceId(deviceId);
      setEmail(emailAddress);
      setzauthToken(zauthToken);
      appInsights.trackEvent({ name: 'CollaborationTab',
      properties: { emailAddress ,zauthToken } })

      let baseURL;
      if (zauthToken) {
        const data = await getSelectedEnv(mstKeysJson);
        if (data && data !== undefined) {
          setEnv(data.selectedEnv);
          baseURL = data.selectedSetupUrl.substring(
            0,
            data.selectedSetupUrl.indexOf("home")
          );
          if (
            baseURL !== undefined &&
            baseURL !== undefined &&
            baseURL !== ""
          ) {
            if (baseURL.includes("http://")) {
              baseURL =
                baseURL.replace(/^http:\/\//i, "https://") +
                "merlinassistadaptor/api/";
            } else {
              baseURL = baseURL + "merlinassistadaptor/api/";
            }
          } else {
            baseURL = data.selectedSetupUrl + "/";
          }
          setSelUrl(baseURL);
          setSelectedTenant(data.selectedTenant);
          setIsdsso(JSON.parse(data.selectedTenant).tenantIsDSSO);
        }
        
        // const error = await tenantAndUserCommon(data,setisErrorMessage,setErrmessage,userObjectId,"collabtabaccess");
        // //check user level access
        // if(error)
        // {
        //   return;
        // }
        processCollaboration(response, data, baseURL,mstKeysJson);
      } else {
        setErrmessage(STRINGS.MST_INVALID_TOKEN_COLLAB);
      }
    } else {
      setErrmessage(mstKeysJson.MST_Something_went_wrong_Please_try_again_after_some_time);
    }
  };

  const processCollaboration = (rosterTable, userRegionTable: any, baseURL: any,mstKeysJson) => {
    if (userRegionTable !== undefined && userRegionTable !== null) {
      const zauthToken = rosterTable.data.zycusAuthToken;
      console.log("Inside processCollaboration zauthToken", zauthToken);
      console.log("Inside processCollaboration selUrl:", selUrl);
      console.log("Inside processCollaboration baseURL:", baseURL);

      if (zauthToken) {
        getTeamsVendorAppDetails(rosterTable.data, userRegionTable, zauthToken, baseURL,mstKeysJson);
      } else {
        setErrmessage(mstKeysJson.MST_Please_Go_to_Chat_Tab_and_Login);
      }
    } else {
      setErrmessage(mstKeysJson.MST_Something_went_wrong_Please_try_again_after_some_time);
    }
  };

  const getTeamsVendorAppDetails = async (
    rosterTable,
    userRegionTable,
    zauthToken,
    baseURL,
    mstKeysJson
  ) => {
    console.log("Inside getTeamsVendorAppDetails baseURL:",baseURL);
    console.log("Inside getTeamsVendorAppDetails selUrl:",selUrl);
    console.log("getTeamsVendorAppDetails poNumber",poNumber);
    const url = selUrl ? selUrl : baseURL;
    // const path = JSON.parse(userRegionTable.selectedTenant).tenantIsDSSO
    //   ? URL.BASE_MERLIN_ASSIST_DSSO_URL
    //   : URL.BASE_MERLIN_ASSIST_URL;
    // const details = {
    //   deviceId:
    //     rosterTable && rosterTable.deviceId && rosterTable.deviceId.toString(),
    //   emailAddress: rosterTable && rosterTable.emailAddress,
    // };
    // const header = JSON.parse(userRegionTable.selectedTenant).tenantIsDSSO
    //   ? getHeaders(zauthToken, details)
    //   : getHeaders(zauthToken, null);
    const path =  URL.BASE_MERLIN_ASSIST_URL;
    const header = getHeaders(zauthToken, null);
    const response = await getCall(
      url,
      path + "/appxtend/getTeamsVendorAppDetails",
      header
    );
    if (response.data.statusCode === 200) {
      if (response !== undefined && response !== null) {
        let records = response?.data?.data;
        let collabAccess =false;
        for (const record of records) {
          if (record.appName === "COLLABORATION") {
            collabAccess =true;
            if (
              record.appManifests[0]?.pageUrl.includes("ms-teams-integration")
            ) {
              if(poNumber){
                console.log("Got poNumber URL==>",record.appManifests[0]?.pageUrl.replace(
                  "ms-teams-integration",
                  "merlin"
                ) + "?poId=" + poNumber + "&" + "aadObjectId="+userObjectId + "&" +"product=" +typeOfDoc)
                setCollaborationURL(
                  record.appManifests[0]?.pageUrl.replace(
                    "ms-teams-integration",
                    "merlin"
                  ) + "?poId=" + poNumber + "&" + "aadObjectId="+userObjectId + "&" +"product=" +typeOfDoc
                );
              }
              else
              {
                console.log("No PO number found",record.appManifests[0]?.pageUrl.replace(
                  "ms-teams-integration",
                  "merlin"
                ) + "?aadObjectId="+userObjectId)
                setCollaborationURL(
                  record.appManifests[0]?.pageUrl.replace(
                    "ms-teams-integration",
                    "merlin"
                  ) + "?aadObjectId="+userObjectId
                );
              }
              
            } else {
              setCollaborationURL(record.appManifests[0]?.pageUrl + "merlin");
            }
          }
        }
        if(!collabAccess)
        {
          setErrmessage(STRINGS.COLLAB_ACCESS);
        }
      } else {
        setErrmessage(STRINGS.MST_INVALID_TOKEN_COLLAB);
      }
    } else if (response.data.statusCode === 401) {
      setErrmessage(STRINGS.MST_INVALID_TOKEN_COLLAB);
    } else {
      setErrmessage(mstKeysJson.MST_Something_went_wrong_Please_try_again_after_some_time);
    }
    app.notifySuccess()
  };

  return (
    <ThemeProvider style={{ background: "inherit" }}>
      <div>
        {errmessage.split("\n").map((t,key) => {
            return <h4 key={key}>{t}</h4>;
        })}
    </div>
     
      {!errmessage?<iframe
        title="Collaboration"
        ref={ref}
    //    onLoad={onLoad}
        className='w-full'
        id='iframe'
        height={height}
        scrolling='no'
        frameBorder='0'
        src={`${collaborationUrl}`}
        width="95%"
      ></iframe>:null}
    </ThemeProvider>
  );
}

export default CollaborationData;
