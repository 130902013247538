import authStore from "../Keys";
import Cookies from "js-cookie";

function AssignLocation() {
  console.log("AssignLocation", window.location.href);
  let customDomainUrl = window.location.href.split("?")[1];
  const authObj = new authStore();
  let uniqueID = (Math.floor(Math.random() * 1000000) + 1).toString();
  console.log(uniqueID);
  localStorage.setItem("uniqueID", uniqueID);
  setCookieForUrl(uniqueID)
  let queryParams = {
    redirectUrl: authObj.redirectUrl,
    //  redirectUrl: "https://bff.zycus.com:3000/app&isMobile=true&uniqueID=",

    authenticationId: uniqueID,
  };

  let authorizeEndpoint = `https://${customDomainUrl}/home/?${toQueryString(
    queryParams
  )}`;
  console.log("authorizeEndpoint", authorizeEndpoint);
  window.location.assign(authorizeEndpoint);

  function setCookieForUrl(valuePass){
    let name = "uniqueID",
    value = valuePass,
    pathe = "/",
    domain = ".zycus.com",
    //domain = "localhost",
    ATS_getExpire = function () {
      return "ATS_getExpire";
    };

  let curCookie =
    name +
    "=" +
    value +
    ", expires=" +
    ATS_getExpire() +
    ",path=" +
    pathe +
    ", domain=" +
    domain;
  Cookies.set("session1", curCookie, {
    domain: ".zycus.com",
    sameSite: "none",
    httpOnly: false,
    secure: true,
  });
  console.log("cookie", window.document.cookie);
  document.cookie = curCookie;
  window.document.cookie = `url=${value}`;
  console.log("cookie", window.document.cookie);
  }

  function toQueryString(queryParams) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
      encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }
    return encodedQueryParams.join("&");
  }

  return <div></div>;
}

export default AssignLocation;
