import { getCall } from "../Api/services";
import { getHeaderswithApprovalList } from "../Headers";
import authStore from "../Keys";

export const getAzureEntity = async (aadObjectId) => {
      const authObject = new authStore();
      let config = {
        method: "get",
        url:
          `https://${authObject.accountName}.table.core.windows.net/${authObject.tableName}(PartitionKey=%27` +
          aadObjectId +
          "%27,RowKey=%27" +
          aadObjectId +
          "%27)" +
          authObject.token,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await getCall(config.url, "", config.headers);
      if (response !== undefined && response !== null) {
       return response.data;
      }
      else{
        return null;
      }
  };

  
  export const getSelectedEnv = async (userObjectId) => {
    const authObject = new authStore();
    var config = {
      method: "get",
      url:
        `https://${authObject.accountName}.table.core.windows.net/${authObject.getSelectedEnvTable}(PartitionKey=%27` +
        userObjectId +
        "%27,RowKey=%27" +
        userObjectId +
        "%27)" +
        authObject.token,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await getCall(config.url, "", config.headers);
    if (response !== undefined && response !== null) {
      return response.data;
    } else {
      return null
    }
  };

  export const getBaseUrl = (data)=>{
    let baseURL;
    baseURL = data.selectedSetupUrl.substring(
        0,
        data.selectedSetupUrl.indexOf("home")
      );
      if (
        baseURL !== undefined &&
        baseURL !== undefined &&
        baseURL !== ""
      ) {
        if (baseURL.includes("http://")) {
          baseURL =
            baseURL.replace(/^http:\/\//i, "https://") +
            "merlinassistadaptor/api/";
        } else {
          baseURL = baseURL + "merlinassistadaptor/api/";
        }
      } else {
        baseURL = data.selectedSetupUrl + "/";
      }

      return baseURL
  }


 export const callGetDetails =async(rosterData,userRegionTenant)=>{

    const baseUrl = getBaseUrl(userRegionTenant)
    const path ='/a/dd/users/getDetails';
    const headers =getHeaderswithApprovalList(rosterData.zycusAuthToken,null,undefined);
    const response = await getCall(
        baseUrl,
        path,
        headers
      );

      if (response.data.statusCode === 200) {
        if (response !== undefined && response !== null) {
            return response.data;
        }}
        else{
            return null;
        }
 } 
