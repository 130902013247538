import CollaborationData from "../components/Collaboration.tsx";
import withAppInsights from '../AppInsights';
import ErrorBoundary from "../error/ErrorBoundary.js";

function Collaboration({poNumber,typeOfDoc}) {
  console.log("Collaboration",poNumber);
  console.log("Collaboration",typeOfDoc);

  return (
    <div>
      <ErrorBoundary>
      <CollaborationData poNumber={poNumber} typeOfDoc={typeOfDoc} />
      </ErrorBoundary>
    </div>
  );
}
export default withAppInsights(Collaboration)
