const STRINGS ={
    "MST_Please_Go_to_Chat_Tab_and_Login": "Please Go to Chat Tab and Login.",
    "MST_Something_went_wrong_Please_try_again_after_some_time": "Something went wrong.Please try again after some time",
    "MST_Hang_on_while_we_get_things_set_up_for_you": "Hang on while we get things set up for you! ....",
    "MST_LoadinginProgress": "Loading...",
    "MST_You_have_no_notification_at_this_moment": "You have no notification at this moment",
    "MST_Date_and_Time":"Date/Time",
    "MST_ID":"ID",
    "MST_Description":"Description",
    "MST_Notification":"Notification",
    "MST_VIEW_DETAILS":"View Details",
    "MST_Search_in_notification":"Search in Notification",
    "MST_INVALID_TOKEN_NOTIFICATION":"It appears that your session is inactive on the Zycus account. \n \n Next, proceed to the \"Chat\" tab in Merlin assist and enter \"Hi Merlin\" to log in. After successful authentication, you can return to access notification features here.",
    "MST_INVALID_TOKEN_COLLAB":"It appears that your session is inactive on the Zycus account. \n \n Next, proceed to the \"Chat\" tab in Merlin assist and enter \"Hi Merlin\" to log in. After successful authentication, you can return to access collaboration features here.",
    "COLLAB_ONLY_IN_WEB":"Collaboration app not supported on mobile. Please use MS Teams on desktop or web.",
    "COLLAB_ACCESS":"You do not have the access to this application",
    "ClearAll":"Clear All",
    "Cancel":"Cancel",
    "Apply":"Apply",
    "FiltersText":"Filters",
    "Date":"Date",
    "MST_LastWeek":"Last Week",
    "MST_LastMonth":"Last Month",
    "MST_DateRange":"DateRange",
    "MST_SelectAll":"Select All",
    "DocumentType":"Document Type",
    "MST_Close":"Close",
    "MST_Looks_like_you_do_not_have_access_to_this_application":"Looks like you do not have access to this application. Please contact your admin for further queries.",
    "MST_You_have_a_document_pending_for_approval": "You have document pending for approval",
    "MST_Document_has_been_rejected_by": "Document has been rejected by",
    "MST_Document_has_been_approved_by": "Document has been Approved by",
    "MST_Document_has_been_actionName_by_actorName": "Document has been {{actionName}} by {{actorName}}",
    "MST_Apply": "Apply",
    "MST_Clear": "Clear",
    "MST_All": "All",
    "MST_Cancel": "Cancel",
    "MST_Select ": "Select",
    "MST_is_pending_for_your_approval": "is pending for your approval.",
    "MST_is_approved": "is approved.",
    "MST_is_rejected": "is rejected.",
    "MST_is_delegated": "is delegated.",
    "MST_has_unknown_status": "has unknown status",
    "MST_Approved": "Approved",
    "MST_Rejected": "Rejected",
    "MST_Delegated": "Delegated",
    "MST_Review": "Review",
    "MST_Pending": "Pending",
    "MST_Event": "Event",
    "MST_Award": "Award",
    "MST_Signoff": "Signoff",
    "MST_Publish": "Publish",
    "MST_Authoring": "Authoring",
    "MST_Requisition": "Requisition",
    "MST_PurchaseOrder": "Purchase Order",
    "MST_Contract": "Contract",
    "MST_Request": "Request",
    "MST_Invoice": "Invoice"
  }

export default STRINGS;