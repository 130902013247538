export function getHeaderwithContentType() {
  return {
    "Content-Type": "application/json",
    version: "1.0.0",
    // "x-forwarded-for": "10.121.3.230",
  };
}

export function getHeaders(token, details) {
  console.log("Header details :", details);
  console.log("\n");
  if (details && details.deviceId !== undefined && details.deviceId !== "") {
   let email;
    if(details.emailAddress)
    {
      email = details.emailAddress;
    }
    else if(details.email)
    {
      email = details.email;
    }
    else{
      email = "";
    }
    return {
      "Content-Type": "application/json",
      authorization: `SAAS_COMMON_BASE_TOKEN_ID=${token}`,
      deviceId: details.deviceId ? details.deviceId.toString() : "",
      emailAddress: email,
      tokenId: token,
      version: "1.0.0",
      // "x-forwarded-for": "10.121.3.230",
    };
  } else {
    return {
      "Content-Type": "application/json",
      authorization: `SAAS_COMMON_BASE_TOKEN_ID=${token}`,
      version: "1.0.0",
      // "x-forwarded-for": "10.121.3.230",
    };
  }
}

export function getHeaderswithApprovalList(token, isapprovallist, details) {
  if (details && details.deviceId !== undefined) {
    return {
      "Content-Type": "application/json",
      authorization: ` SAAS_COMMON_BASE_TOKEN_ID=${token}`,
      //  isapprovallist: true,
      deviceId: details.deviceId.toString(),
      emailAddress: details.email,
      tokenId: token,
      version: "1.0.0",

      // "x-forwarded-for": "10.121.3.230",
    };
  } else {
    return {
      "Content-Type": "application/json",
      authorization: ` SAAS_COMMON_BASE_TOKEN_ID=${token}`,
      version: "1.0.0",
      // isapprovallist: true

      // "x-forwarded-for": "10.121.3.230",
    };
  }
}
