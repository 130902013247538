//Get post put delete calls pass Url Header postdata

import axios from "axios";
export const getCall = async (url, path, headers) => {
  let sendUrl = `${url}${path}`;
  console.log("Urlpassed", sendUrl);
  console.log("headerspassed", JSON.stringify(headers));

  let response = await axios
    .get(sendUrl, {
      headers: headers,
      data: null,
    })
    .catch((error) => {
      console.log("error", error);
      return error.response;
    });
  return response;
};

export const postCall = async (Url, path, header, body) => {
  console.log("Url", Url + path);
  console.log("body", body);
  console.log("token", header.Authorization);
  let response = await axios
    .post(Url + path, body, {
      headers: header,
    })
    .catch((error) => {
      console.log("error", error);
      return error.response;
    });
  return response;
};

export const putCall = async (Url, path, header, body) => {
  const res = await axios
    .put(Url + path, body, { headers: header })
    .catch((error) => {
      console.log("error", error);
      return error.response;
    });
  return res;
};
